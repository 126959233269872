import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Layout } from "src/components/Layout"
import { Helmet } from "src/components/Helmet"
import { Container } from "src/components/Container"
import { ProductCard } from "src/components/ProductCard"

const SubcategoryTemplate = ({
  data: {
    subcategory: { shortName, product },
  },
}) => {
  return (
    <>
      <Helmet title={shortName}></Helmet>
      <Layout>
        <Container>
          <Heading>{shortName}</Heading>
          {!product.length > 0 && (
            <h2>
              Нет товаров
              <span role="img" aria-label="monkey">
                🙊
              </span>
            </h2>
          )}
          <Inner>
            {product.map(({ id, slug, image, name, price }) => (
              <ProductCard
                key={id}
                {...{ slug, image, name, price }}
              ></ProductCard>
            ))}
          </Inner>
        </Container>
      </Layout>
    </>
  )
}

export default SubcategoryTemplate

export const query = graphql`
  query($id: String!) {
    subcategory: datoCmsSubcategory(id: { eq: $id }) {
      shortName
      product {
        name
        slug
        id
        image {
          fluid(
            maxWidth: 320
            imgixParams: { fm: "jpg", auto: "compress, format" }
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
        price
      }
    }
  }
`

const Inner = styled.div`
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
  flex-wrap: wrap;

  margin-bottom: 40px;
`

const Heading = styled.h1`
  background-color: #fff;
  border-radius: 6px;
  padding: 16px;
  margin: 0;
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 32px;

  color: ${({ theme }) => theme.colors.contrast};

  &::first-letter {
    text-transform: uppercase;
  }
`
